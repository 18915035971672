import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The elevation is the level of the surface position where the content and components are located. The elevation is based on the Z-Index and this is the principle to classified between components with focus hierarchy through depth.`}</p>
    </PageDescription>
    <h2>{`Elevation System`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "31.59722222222222%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABGklEQVQY02NgIBOoVK1l0Cqaz6Kdv4RZP2clq1LCOn6Xwo18DHa1LurOJT5yDkXe8rGrS4XT9rQJpmzpEIhe0y5sWxirYpcSJW+dHC0fPWe6UNz8RYLRMxcLRU1bKRg1abVQ3MQNQoHtmwST+3YI96+9xH708EE2BqqDD+8/TPn189eyb9++Lfz+/fviHz9+LP7y5cvi79++L/765eviX79+Lf729esikPivnz/B7N+/fy8CqwGqf/v27eKvX78u+P79x9qfP386MAA1zPj79+8qoKJVf/78WQnEy4FsMAbKgegVQIUrgJpXATWu/vDhw7r3799vePXq1canT59uefjw4fYbN27svHfv3pljx476U8ujrEDMDcQcAFehsmzAYGG3AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "elevation system",
        "title": "elevation system",
        "src": "/static/3c3e10fbd0c5d8fed97f1481e25aa662/3cbba/ELEVATION-SYSTEM.png",
        "srcSet": ["/static/3c3e10fbd0c5d8fed97f1481e25aa662/7fc1e/ELEVATION-SYSTEM.png 288w", "/static/3c3e10fbd0c5d8fed97f1481e25aa662/a5df1/ELEVATION-SYSTEM.png 576w", "/static/3c3e10fbd0c5d8fed97f1481e25aa662/3cbba/ELEVATION-SYSTEM.png 1152w", "/static/3c3e10fbd0c5d8fed97f1481e25aa662/dfa9e/ELEVATION-SYSTEM.png 1369w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Use Case`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Level`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`00 Ground`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use for background and container (fieldset, table header, table cell, divider, content grouping, etc)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`01 Panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use for background and container (fieldset, table header, table cell, divider, content grouping, etc)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`02 Bump`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use only for button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`03 Platform`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use for card container (content grouping) above ground or panel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`04 Hover`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use for button hover state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`05 Float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use for container hover state and sticky navigation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`06 Fly`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use for modal dialog and popover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`07 Soar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These elevations can be used to spice up container appearance and/or its hover/active state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`08 Orbin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These elevations can be used to spice up container appearance and/or its hover/active state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`09 Spaceflight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These elevations can be used to spice up container appearance and/or its hover/active state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19 Lunar Orbit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These elevations can be used to spice up container appearance and/or its hover/active state`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      